.center {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 3vw;
  width: 80vw;
  margin-top: 3vw;
  margin-bottom: 3vw;
}

.outside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
}

.titleText {
  animation: slide-in-left 1s ease-in-out;
  font-weight: 700;
  color: var(--white);
  font-size: 9vw;
  font-family: 'Titillium Web', sans-serif;
  line-height: 1px;
}

.subtitleText {
  margin-bottom: 3rem;
  animation: slide-in-left 1.3s ease-in-out;
  font-weight: 500;
  color: var(--white);
  line-height: 1px;
  font-size: 3vw;
}

.textBold {
  color: white;
  font-weight: 700;
  font-size: 3vw;
}

.innerPaypal {
  padding-top: 2vw;
  padding-bottom: 1vw;
  margin-left: 3vw;
  margin-right: 3vw;
}

.textBolder {
  font-weight: 600;
  color: var(--white);
  font-size: 3vw;
}

.text {
  font-weight: 500;
  color: var(--white);
  font-size: 4vw;
}

.receiptText {
  font-weight: 500;
  color: var(--white);
  font-size: 1.5vw;
  line-height: 1vw;
}

.smallText {
  font-weight: 500;
  color: lightcyan;
  font-size: 1vw;
}

.smallTextPadding {
  font-weight: 500;
  color: lightcyan;
  font-size: 1vw;
  padding-left: 3vw;
  padding-right: 3vw;
}

.textMid {
  color: white;
  font-size: 3vw;
  padding-left: 3vw;
  font-weight: 600;
}

@keyframes slide-in-left {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
