.onRenderPop {
  animation: 0.2s ease-in 0s 1 popAppear;
}

.buttonShiny {
  align-items: center;
  background-image: linear-gradient(45deg, #09009f, #00ed8b 80%);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 18px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 50px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

@keyframes popAppear {
  0% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(1);
  }
}

.buttonShiny:active,
.buttonShiny:hover {
  outline: 0;
}

.buttonShiny span {
  background-color: rgb(5, 6, 45);
  /* padding: 18px 24px; */
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
  font-size: 20px;
  align-content: center;
}

.buttonShiny span:active {
  background: none;
}

.mapsDiv {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.mapsDiv:active {
  background: none;
}

.buttonShiny:active {
  transform: scale(0.9);
}
