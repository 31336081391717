body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'TTNorms';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, 'TTNorms';
}

.input {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 10px;
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.input:focus {
  appearance: none;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 300px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.flip-card-inner-flipped {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  color: #fff;
  padding: 10px;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -5px #2196f3;
  }
  to {
    box-shadow: 0 0 10px 5px #2196f3;
  }
}

@keyframes orange-glow {
  from {
    box-shadow: 0 0 10px -5px #ff733e;
  }
  to {
    box-shadow: 0 0 10px 5px #ff733e;
  }
}

.pullee {
  width: 13rem;
  appearance: none;
  background-color: rgba(255, 255, 255, 0);
}
.pullee:active::-webkit-slider-thumb {
  appearance: none;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:active::-moz-range-thumb {
  border: 0;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:active::-ms-thumb {
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:focus {
  outline: none;
}
.pullee::-webkit-slider-thumb {
  appearance: none;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background: #00bc00;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-moz-range-thumb {
  border: 0;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 30%;
  background: #00bc00;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-ms-thumb {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 30%;
  background: #00bc00;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-webkit-slider-runnable-track {
  height: 30px;
  padding: 3px;
  box-sizing: content-box;
  border-radius: 10px;
  background-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  border-color: #00bc00;
}
.pullee::-moz-range-track {
  height: 30px;
  padding: 3px;
  box-sizing: content-box;
  border-radius: 10px;
  background-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  border-color: #00bc00;
}
.pullee::-moz-focus-outer {
  border: 0;
}
.pullee::-ms-track {
  border: 0;
  height: 30px;
  padding: 3px;
  box-sizing: content-box;
  border-radius: 10px;
  background-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  border-color: #00bc00;
  color: transparent;
}
.pullee::-ms-fill-lower,
.pullee::-ms-fill-upper {
  background-color: transparent;
}
.pullee::-ms-tooltip {
  display: none;
}

.onRenderPop {
  animation: 0.2s ease-in 0s 1 popAppear;
}

.launchAppButton {
  align-items: center;
  /* background-image: linear-gradient(45deg, #09009f, #00ed8b 80%); */
  background-color: white;
  border: 0;
  border-radius: 30px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 18px;
  justify-content: center;
  line-height: 1em;
  width: fit-content;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

@keyframes popAppear {
  0% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(1);
  }
}

.launchAppButton:active,
.launchAppButton:hover {
  outline: 0;
}

.launchAppButton span {
  background-color: #5271ff;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  transition: 300ms;
  font-size: 20px;
  align-content: center;
}

.launchAppButton span:active {
  background: none;
  color: #5271ff;
}

.launchAppButton:active {
  transform: scale(0.9);
}

.price-strikethrough {
  position: relative;
}
.price-strikethrough:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: #ff331f;

  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
