.card {
  position: relative;
  width: 90%;
  height: 175px;
  background: lightgrey;
  box-shadow: #d11bff42 0 15px 40px -5px;
  z-index: 1;
  border-radius: 21px;
  overflow: hidden;
}

.card__content {
  background: linear-gradient(
    rgba(255, 255, 255, 0.473),
    rgba(150, 150, 150, 0.25)
  );
  z-index: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 21px;
}

.card .blob {
  position: absolute;
  z-index: -1;
  border-radius: 5em;
  width: 200px;
  height: 200px;
}

.card .blob:nth-child(2) {
  left: 110px;
  top: -20px;
  z-index: -1;
  background: #bd08ff;
}

.card .blob:nth-child(3) {
  right: -80px;
  bottom: -130px;
  background: #ff9008;
}

.card .blob:nth-child(4) {
  left: -50px;
  top: -90px;
  background: #ff9008;
}

.card .blob:nth-child(5) {
  right: -80px;
  top: -100px;
  background: #ff0e62;
}

.card .blob:nth-child(6) {
  left: -40px;
  top: 100px;
  background: #ff0e62;
}

.card .blob:nth-child(7) {
  left: 100px;
  top: 180px;
  background: #0061ff;
}

.activeBatchCard {
  animation: 0.2s ease-in 0s 1 popAppear, ani 5s linear infinite reverse;
  /* animation-direction: reverse; */
  background-size: 400%;
  border: none;
  background: linear-gradient(
    35deg,
    #09009f 40%,
    rgba(0, 237, 139, 1) 50%,
    #09009f 60%
  );
  background-size: 400%;
  border-radius: 30px;
  overflow: hidden;
}

@keyframes ani {
  0% {
    background-position: -20%;
  }
  100% {
    background-position: 100%;
  }
}

@keyframes popAppear {
  0% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(1);
  }
}
