.blog-post {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 100px;
}

.blog-article {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
}

.blog-h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.blog-h2 {
  font-size: 2em;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.blog-p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.blog-footer-p {
  text-align: center;
  font-weight: bold;
  color: #6c757d;
}

.blog-header-p {
  font-style: italic;
  color: #6c757d;
}
