.premium {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 12px 12px 12px 20px;
  height: auto;
  width: 80vw;
  border-radius: 12px;
  transition: transform 0.2s, box-shadow 0.2s;
  background: linear-gradient(145deg, #fe0831, #fff700a5);
}

.premium:active {
  transform: scale(0.97);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.premium h3 {
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #131219;
}

.basic {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 12px 12px 12px 20px;
  height: auto;
  width: 80vw;
  border-radius: 12px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.basic:active {
  transform: scale(0.97);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.basic h3 {
  font-size: 1.4rem;
  margin-bottom: 8px;
  color: #131219;
}

.animated-border {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 12px 12px 12px 20px;
  height: auto;
  width: 80vw;
  border-radius: 12px;
  background: linear-gradient(135deg, #8b18ff, #2a5fff) padding-box,
    conic-gradient(from var(--angle), #141414, #ffffff, #141414) border-box;
  animation: rotate 3s linear infinite;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.animated-border:active {
  transform: scale(0.97);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.animated-border h3 {
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #131219;
}

.animated-border ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.animated-border li {
  color: #333;
  margin-bottom: 6px;
  font-size: 1rem;
}

/* Keyframes for rotating gradient */
@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

/* Custom property for animating the angle of the gradient */
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
